import React from "react"
import {colors} from "../theme"
import {Flex, Box, Text} from "@chakra-ui/core"
import {format, isSameMonth} from "date-fns"

export default function DayDot({
  forDate,
  effectiveDate,
  day,
}: {
  forDate: Date
  effectiveDate: Date
  day?: {note?: string | null; score: null | number}
}) {
  const opacity = isSameMonth(forDate, effectiveDate) ? 1 : 0.5

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      cursor={day?.note ? "pointer" : undefined}
    >
      <Box
        w={8}
        h={8}
        opacity={opacity}
        rounded="full"
        backgroundColor={
          day?.score != null
            ? scoreColor[`${day.score}` as "-1" | "0" | "1"]
            : undefined
        }
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontWeight="semibold" color="white" fontSize="sm" as="span">
          {format(forDate, "d")}
        </Text>
      </Box>
      <Box
        mt={1}
        width={1}
        height={1}
        rounded="full"
        backgroundColor={day?.note ? "white" : "transparent"}
        alignSelf="center"
      />
    </Flex>
  )
}

const scoreColor = {
  "-1": colors.bad,
  "0": colors.ok,
  "1": colors.good,
}
