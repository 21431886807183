import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  PseudoBox,
} from "@chakra-ui/core"
import gql from "graphql-tag"
import React from "react"
import {useQuery} from "react-apollo"
import {Route, Switch} from "react-router-dom"
import Dashboard from "./dashboard"
import {app} from "./firebase"
import MyJournal from "./my-journal"
import {Test} from "./__generated__/Test"
import TeamList from "./team-list"

export function Layout() {
  return (
    <Flex
      minHeight="100%"
      position="absolute"
      width="100%"
      backgroundColor="gray.50"
      flexDirection="column"
    >
      <Switch>
        <Route path="/">
          <Stack
            isInline
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            spacing={0}
          >
            <Flex flexDir="column" flex="1" overflowX="hidden" overflowY="auto">
              <Me />
              <Dashboard />
              <Box h={6} />
            </Flex>
            <Stack
              backgroundColor="primary"
              width="320px"
              overflowX="hidden"
              overflowY="auto"
              pb={6}
            >
              <MyJournal backgroundColor={"primary"} />
              <TeamList backgroundColor={"primary"} />
            </Stack>
          </Stack>
        </Route>
      </Switch>
    </Flex>
  )
}

function Me() {
  const {data} = useQuery<Test>(gql`
    query Test {
      me {
        imageUrl
        name
      }
    }
  `)

  if (!data || !data.me) {
    return null
  }

  return (
    <Stack pl={6} pr={6} pt={6} pb={4} spacing={0}>
      <Stack isInline spacing={4} flexDir="row" alignItems="center">
        <PseudoBox
          transition={"transform 200ms ease-in-out"}
          _hover={{
            transform: "scale(1.03)",
          }}
        >
          <Menu>
            <MenuButton>
              <Avatar
                border="4px solid white"
                shadow="lg"
                size="xl"
                name={data.me.name}
                src={data.me.imageUrl ?? undefined}
              />
            </MenuButton>
            <MenuList placement="right">
              <MenuItem
                onClick={() => {
                  app.auth!().signOut()
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </PseudoBox>
        <Box>
          <Text fontSize="3xl">
            <span role="img" aria-label="Waving hand">
              👋{" "}
            </span>
            Hello {data.me.name.split(" ")[0]}
          </Text>
          <Text color={"gray.600"}>
            See how you're doing with your stats below
          </Text>
        </Box>
      </Stack>
    </Stack>
  )
}
