import React, {useState, useEffect} from "react"
import {VictoryPie, VictoryLabel} from "victory"
import {
  MyStatsFeed_myStatsFeed_RingsStatsItem,
  MyStatsFeed_myStatsFeed_RingsStatsItem_values,
} from "./__generated__/MyStatsFeed"
import {serverColor} from "../theme"
import {Flex, SimpleGrid} from "@chakra-ui/core"

export const Rings = (props: {
  config: MyStatsFeed_myStatsFeed_RingsStatsItem
}) => {
  return (
    <SimpleGrid
      p={2}
      minChildWidth={"128px"}
      maxChildWidth={"280px"}
      spacing={2}
    >
      {props.config.values.map((v, i) => (
        <OneRing key={i} {...v} />
      ))}
    </SimpleGrid>
  )
}

function OneRing(props: MyStatsFeed_myStatsFeed_RingsStatsItem_values) {
  const [value, setValue] = useState(0)
  useEffect(() => {
    setValue(props.fraction)
  }, [props.fraction])

  const data = [
    {
      x: 1,
      y: value,
      text: props.label,
    },
    {x: 2, y: 1 - value},
  ]

  return (
    <Flex
      alignSelf="center"
      justifySelf="center"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <svg
        style={{position: "relative", zIndex: 1}}
        viewBox="0 0 400 400"
        width="100%"
        height="100%"
      >
        <VictoryPie
          standalone={false}
          animate={{duration: 1000}}
          width={400}
          height={400}
          data={data}
          innerRadius={120}
          cornerRadius={60}
          labels={[]}
          style={{
            data: {
              fill: ({datum}) => {
                return datum.x === 1 ? serverColor(props.color) : "transparent"
              },
            },
          }}
        />
        <VictoryLabel
          textAnchor="middle"
          verticalAnchor="middle"
          x={200}
          y={200}
          text={`${data[0].text}`}
          style={{fontSize: 45}}
        />
      </svg>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: "center",
        }}
      >
        <svg viewBox="0 0 400 400" width="100%" height="100%">
          <VictoryPie
            standalone={false}
            width={400}
            height={400}
            data={[
              {
                x: 1,
                y: 100,
              },
            ]}
            innerRadius={120}
            cornerRadius={10}
            labels={[]}
            style={{
              data: {
                fill: "#f0f0f0",
              },
            }}
          />
        </svg>
      </div>
    </Flex>
  )
}
