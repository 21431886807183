import {
  Avatar,
  Badge,
  Box,
  Flex,
  SimpleGrid,
  Stack,
  Tooltip,
} from "@chakra-ui/core"
import {endOfWeek, isWeekend, parseISO, startOfWeek} from "date-fns"
import gql from "graphql-tag"
import React from "react"
import {useQuery} from "react-apollo"
import DayDot from "../components/DayDot"
import SidebarHeader from "../components/SidebarHeader"
import {formatIsoDay} from "../utils"
import {TeamList, TeamListVariables} from "./__generated__/TeamList"

export default function TeamListComponent(props: {backgroundColor: string}) {
  const today = new Date()

  const startOfWeekRange = startOfWeek(today, {weekStartsOn: 1})
  const endOfWeekRange = endOfWeek(today, {weekStartsOn: 1})
  const {data} = useQuery<TeamList, TeamListVariables>(query, {
    variables: {
      fromDate: formatIsoDay(startOfWeekRange),
      toDate: formatIsoDay(endOfWeekRange),
    },
  })

  const team = data?.me?.memberships[0].team

  if (!team) {
    return null
  }

  return (
    <>
      <Box
        zIndex={9}
        position="sticky"
        top="0"
        backgroundColor={props.backgroundColor}
      >
        <SidebarHeader
          backgroundColor={props.backgroundColor}
          text={team.name}
        />
      </Box>
      <Stack spacing={4}>
        {team.acceptedMembers?.map((m) => {
          return (
            <Stack pr={1} pl={2} key={m.id} isInline alignItems="center">
              <Tooltip
                placement="left"
                hasArrow
                aria-label={m.name}
                label={m.name}
              >
                <Flex
                  alignItems="center"
                  flexDir="column"
                  flexGrow={0}
                  flexShrink={0}
                >
                  <Avatar name={m.name} src={m.imageUrl ?? undefined} />
                  <Badge fontWeight="normal" minW={4} mt={-2} zIndex={1}>
                    {m.name
                      .split(" ")
                      .map((a) => a[0])
                      .join("")}
                  </Badge>
                </Flex>
              </Tooltip>
              <SimpleGrid flex={1} columns={5}>
                {m.days?.map((d) => {
                  const dayDate = parseISO(d.date)
                  if (isWeekend(dayDate)) {
                    return null
                  }

                  return (
                    <DayDot
                      key={d.date}
                      day={d}
                      effectiveDate={today}
                      forDate={dayDate}
                    />
                  )
                })}
              </SimpleGrid>
            </Stack>
          )
        })}
      </Stack>
    </>
  )
}

const query = gql`
  query TeamList($fromDate: String!, $toDate: String!) {
    me {
      memberships {
        team {
          id
          name

          labels {
            id
            color
            title
            memberCount
          }

          acceptedMembers {
            id
            imageUrl
            name
            earliestDate
            days(from: $fromDate, to: $toDate) {
              id
              score
              date
              question
            }
          }
        }
      }
    }
  }
`
