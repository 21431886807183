import {app} from "../firebase"
import {ApolloClient} from "apollo-client"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory"
import {BatchHttpLink} from "apollo-link-batch-http"

import {setContext} from "apollo-link-context"
import {from} from "apollo-link"

import introspectionQueryResultData from "./fragmentTypes.json"

const useLocal = false
const uri = useLocal
  ? "http://localhost:5000/dottie-teams/us-central1/api/graphql"
  : "https://us-central1-dottie-teams.cloudfunctions.net/api/graphql"

const authMiddleware = setContext(async (_operation, previous) => {
  const user = app.auth!().currentUser
  if (user) {
    const tokenResult = await user.getIdTokenResult()
    return {
      headers: {
        ...previous.headers,
        token: tokenResult.token,
      },
    }
  } else {
    return previous
  }
})

const httpLink = new BatchHttpLink({uri})

const client = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    freezeResults: true,
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  }),
})

export default client
