import React from "react"
import {Layout} from "./Layout"
import {Auth} from "./Auth"
import {ThemeProvider, CSSReset, theme} from "@chakra-ui/core"
import {ApolloProvider} from "react-apollo"

import apolloClient from "./apollo-client"
import {colors} from "./theme"

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider
        theme={{
          ...theme,
          colors: {
            ...theme.colors,
            primary: colors.primary,
          },
        }}
      >
        <CSSReset />
        <Auth>
          <Layout />
        </Auth>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
