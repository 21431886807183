import React, {useState, useEffect} from "react"
import {
  MyStatsFeed_myStatsFeed_MetricStatsItem,
  MyStatsFeed_myStatsFeed_MetricStatsItem_values,
} from "./__generated__/MyStatsFeed"
import {VictoryAnimation} from "victory"
import {Flex, Text, Heading} from "@chakra-ui/core"
import {serverColor} from "../theme"

export const Metric = (props: {
  config: MyStatsFeed_myStatsFeed_MetricStatsItem
}) => {
  return (
    <Flex flex={1} flexWrap="wrap">
      {props.config.values.map((v, i) => (
        <Flex
          key={i}
          flex={1}
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          ml={6}
          mr={6}
          minW={"9rem"}
          mt={6}
          mb={6}
        >
          <OneMetric {...v} />
        </Flex>
      ))}
    </Flex>
  )
}

function OneMetric(props: MyStatsFeed_myStatsFeed_MetricStatsItem_values) {
  const [v, setV] = useState(0)
  const isInteger = Math.round(props.value) === props.value
  useEffect(() => {
    setV(props.value)
  }, [props.value])

  return (
    <>
      <VictoryAnimation easing="cubicInOut" duration={2500} data={{v}}>
        {(nextProps: any) => {
          return (
            <Heading
              lineHeight={1}
              textAlign="center"
              color={serverColor(props.color)}
              fontWeight={500}
              fontSize="6em"
            >
              {isInteger ? Math.round(nextProps.v) : nextProps.v.toFixed(2)}
            </Heading>
          )
        }}
      </VictoryAnimation>
      <Text textAlign="center">{props.label}</Text>
    </>
  )
}
