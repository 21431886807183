import React, {useState, useEffect} from "react"
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  useToast,
  Flex,
  Stack,
  Heading,
  Spinner,
} from "@chakra-ui/core"
import {app} from "./firebase"
import {User} from "@firebase/auth-types"

import {HashRouter} from "react-router-dom"

function useCurrentUser() {
  const auth = app.auth ? app.auth() : undefined
  const [currentUser, setCurrentUser] = useState<"unknown" | null | User>(
    "unknown",
  )

  useEffect(() => {
    auth!.onAuthStateChanged(setCurrentUser)
  }, [auth])

  return currentUser
}

export function Auth(props: {children: React.ReactNode}) {
  const user = useCurrentUser()
  const toast = useToast()

  if (user === "unknown") {
    return (
      <Flex
        height="100%"
        width="100%"
        position="absolute"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner />
      </Flex>
    )
  }

  if (!user) {
    return (
      <Flex align="center" direction="column" justify="center">
        <Login
          onSubmit={(email, password) => {
            app.auth!()
              .signInWithEmailAndPassword(email, password)
              .catch(e => {
                toast({
                  status: "error",
                  title: e.message,
                  position: "top",
                })
              })
          }}
        />
      </Flex>
    )
  }

  return <HashRouter>{props.children}</HashRouter>
}

function Login(props: {onSubmit: (email: string, password: string) => void}) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const submit = () => props.onSubmit(email, password)

  return (
    <Stack
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      backgroundColor="primary"
      alignItems="center"
      justifyContent="center"
    >
      <Box flex={1} />
      <Heading color="white">Login to Dottie</Heading>
      <Box bg="white" minW="sm" rounded="md" shadow="lg" maxWidth="sm" p="6">
        <form onSubmit={submit}>
          <FormControl marginBottom="4">
            <FormLabel htmlFor="email">Email address</FormLabel>
            <Input
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value)
              }}
              type="email"
              id="email"
              aria-describedby="email-helper-text"
            />
            <FormHelperText id="email-helper-text">
              We'll never share your email.
            </FormHelperText>
          </FormControl>

          <FormControl marginBottom="4">
            <FormLabel htmlFor="email">Password</FormLabel>
            <Input
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value)
              }}
              type="password"
              id="password"
              aria-describedby="password-helper-text"
            />
            <FormHelperText id="password-helper-text">
              Must be at least 8 characters
            </FormHelperText>
          </FormControl>

          <Button
            variantColor="blue"
            backgroundColor="primary"
            color="white"
            rounded="full"
            onClick={submit}
          >
            Log in
          </Button>
        </form>
      </Box>
      <Box flex={3} />
    </Stack>
  )
}
