import React from "react"
import {MyStatsFeed_myStatsFeed_TrendlineStatsItem} from "./__generated__/MyStatsFeed"
import {VictoryArea} from "victory"
import {Flex, Text} from "@chakra-ui/core"
import {format, parseISO} from "date-fns"

export const Trendline = (props: {
  config: MyStatsFeed_myStatsFeed_TrendlineStatsItem
}) => {
  return (
    <>
      <VictoryArea
        interpolation="basis"
        data={props.config.values.map(v => ({
          x: v.date,
          y: v.value,
        }))}
        theme={{
          area: {
            width: 400,
            height: 200,
            colorScale: [],
            padding: 0,
          },
        }}
        style={{
          parent: {
            display: "flex",
            alignItems: "center",
          },
          data: {
            fill: "rgb(76, 116, 233)",
            fillOpacity: 0.2,
            stroke: "rgb(76, 116, 233)",
            strokeWidth: 3,
          },
        }}
        animate
      />
      <Flex justifyContent="space-between" pl={2} pr={2}>
        <Text fontSize="sm">{miniDate(props.config.values[0].date)}</Text>
        <Text>
          {miniDate(props.config.values[props.config.values.length - 1].date)}
        </Text>
      </Flex>
    </>
  )
}

function miniDate(input: string): string {
  return format(parseISO(input), "d MMM")
}
