import {Box, Flex, SimpleGrid, Spinner, Stack, Text} from "@chakra-ui/core"
import {
  addDays,
  addMonths,
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isWeekend,
  parseISO,
  startOfMonth,
  startOfWeek,
} from "date-fns"
import {format} from "date-fns/esm"
import gql from "graphql-tag"
import indexBy from "ramda/es/indexBy"
import React, {useState} from "react"
import {useQuery} from "react-apollo"
import DayDotWithPopover from "../components/DayDotWithPopover"
import MonthNavigation from "../components/MonthNavigation"
import SidebarHeader from "../components/SidebarHeader"
import {formatIsoDay} from "../utils"
import {
  MyJournal,
  MyJournalVariables,
  MyJournal_me_days,
} from "./__generated__/MyJournal"

export default function MyJournalComponent(props: {backgroundColor: string}) {
  const [effectiveDate, setEffectiveDate] = useState(startOfMonth(new Date()))
  const [firstDay, setFirstDay] = useState<number>()

  const from = startOfMonth(effectiveDate)
  const to = endOfMonth(effectiveDate)
  const {data, loading} = useQuery<MyJournal, MyJournalVariables>(query, {
    notifyOnNetworkStatusChange: true,
    variables: {
      from: formatIsoDay(from),
      to: formatIsoDay(to),
    },
    onCompleted: (d) => {
      if (d.me?.earliestDate) {
        setFirstDay(+parseISO(d.me.earliestDate))
      }
    },
  })

  const startOfWeekRange = startOfWeek(from, {weekStartsOn: 1})
  const endOfWeekRange = endOfWeek(to, {weekStartsOn: 1})

  const firstWeekIsAllLastMonth = !isSameMonth(
    addDays(startOfWeekRange, 4),
    effectiveDate,
  )

  const range = eachDayOfInterval({
    start: addWeeks(startOfWeekRange, firstWeekIsAllLastMonth ? 1 : 0),
    end: endOfWeekRange,
  })

  const daysIndexedByDay: Record<string, MyJournal_me_days | undefined> =
    loading || !data?.me?.days ? {} : indexBy((x) => x.date, data.me.days)

  return (
    <Box>
      <SidebarHeader
        backgroundColor={props.backgroundColor}
        text="My Journal"
      />
      <Stack
        position="relative"
        minH={"sm"}
        backgroundColor="rgba(0, 0, 0, 0.05)"
      >
        <MonthNavigation
          currentDate={effectiveDate}
          from={firstDay ?? addMonths(new Date(), -1)}
          to={new Date()}
          setCurrentDate={setEffectiveDate}
        />
        <SimpleGrid columns={5} spacingX={5} spacingY={4} pl={6} pr={6}>
          {range.slice(0, 5).map((d, i) => {
            return (
              <Text key={i} textAlign="center" color="white">
                {format(d, "EEEEE")}
              </Text>
            )
          })}
        </SimpleGrid>
        <SimpleGrid
          opacity={loading ? 0.2 : 1}
          columns={5}
          spacingX={5}
          spacingY={4}
          pl={6}
          pr={6}
        >
          {range.map((d) => {
            if (isWeekend(d)) {
              return null
            }

            const date = formatIsoDay(d)
            const day = daysIndexedByDay[date]
            return (
              <DayDotWithPopover
                key={date}
                forDate={d}
                effectiveDate={effectiveDate}
                day={day}
              />
            )
          })}
        </SimpleGrid>
        {loading ? loadingSpinner : null}
      </Stack>
    </Box>
  )
}

const loadingSpinner = (
  <Flex
    position="absolute"
    top={0}
    left={0}
    right={0}
    bottom={0}
    alignItems="center"
    justifyContent="center"
  >
    <Spinner color="white" position="absolute" top="50%" />
  </Flex>
)

const query = gql`
  query MyJournal($from: String!, $to: String!) {
    me {
      earliestDate
      days(from: $from, to: $to) {
        date
        score
        question
        note
      }
    }
  }
`
