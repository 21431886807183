import React from "react"
import ReactDOM from "react-dom"
import App from "./Root"
import * as serviceWorker from "./serviceWorker"

const renderApp = () =>
  ReactDOM.render(<App />, document.getElementById("root"))

renderApp()

if ((module as any).hot) {
  ;(module as any).hot.accept("./Root", () => renderApp())
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
