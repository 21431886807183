import React from "react"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Box,
} from "@chakra-ui/core"
import DayDot from "./DayDot"
import {format} from "date-fns"

export default function DayDotWithPopover({
  forDate,
  effectiveDate,
  day,
}: {
  forDate: Date
  effectiveDate: Date
  day?: {
    question: string | null
    note: string | null
    score: null | number
    date: string
  }
}) {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Box>
          <DayDot forDate={forDate} effectiveDate={effectiveDate} day={day} />
        </Box>
      </PopoverTrigger>
      {day?.note ? (
        <PopoverContent usePortal zIndex={9}>
          <PopoverArrow />
          <PopoverHeader fontWeight="semibold">
            {format(forDate, "d MMMM")}
          </PopoverHeader>
          {day.question ? (
            <PopoverBody color="gray.500" fontSize="sm" mb={0} pb={0}>
              {day.question}
            </PopoverBody>
          ) : null}
          <PopoverBody fontSize="sm">{day.note}</PopoverBody>
        </PopoverContent>
      ) : null}
    </Popover>
  )
}
