import React from "react"
import gql from "graphql-tag"
import {useQuery} from "react-apollo"
import {MyStatsFeed, MyStatsFeed_myStatsFeed} from "./__generated__/MyStatsFeed"
import {Spinner, Text, Stack, Heading, SimpleGrid, Flex} from "@chakra-ui/core"
import {Trendline} from "./Trendline"
import {Rings} from "./Rings"
import {Metric} from "./Metric"

export default function Dashboard() {
  const {data, loading} = useQuery<MyStatsFeed>(statsFeedQuery)

  if (!data && loading) {
    return (
      <Flex flex="1" height="100%" alignItems="center" justifyContent="center">
        <Spinner color="primary" />
      </Flex>
    )
  }

  if (!data) {
    return <Text>Error</Text>
  }

  return (
    <SimpleGrid
      flex="1"
      p={6}
      minChildWidth={"340px"}
      maxChildWidth={"500px"}
      spacing={6}
    >
      {data.myStatsFeed.map((x, i) => {
        return (
          <Stack
            key={i}
            backgroundColor="white"
            spacing={2}
            pt={4}
            pb={4}
            rounded="lg"
            shadow="lg"
          >
            {x.title ? (
              <Heading fontWeight={500} textAlign="center" size="md">
                {x.title}
              </Heading>
            ) : null}
            <Flex flex="1" flexDirection="column" justifyContent="center">
              <StatsContent item={x} />
            </Flex>
            {x.subtitle ? (
              <Heading fontWeight={500} textAlign="center" size="sm">
                {x.subtitle}
              </Heading>
            ) : null}
          </Stack>
        )
      })}
    </SimpleGrid>
  )
}

function StatsContent({item}: {item: MyStatsFeed_myStatsFeed}) {
  switch (item.__typename) {
    case "TrendlineStatsItem":
      return <Trendline config={item} />

    case "RingsStatsItem":
      return <Rings config={item} />

    case "MetricStatsItem":
      return <Metric config={item} />
  }

  return null
}

const statsFeedQuery = gql`
  fragment StatsSubjectFragment on StatsItemSubject {
    ... on MemberLabel {
      id
      title
      color
    }
    ... on Person {
      id
      name
      imageUrl
    }
    ... on Team {
      id
      name
      imageUrl
    }
  }

  query MyStatsFeed {
    myStatsFeed {
      __typename
      title
      subtitle
      scope {
        startDate
        endDate
        subject {
          __typename
          ...StatsSubjectFragment
        }
      }

      ... on RingsStatsItem {
        values {
          color
          fraction
          label
        }
      }

      ... on BarListStatsItem {
        values {
          color
          label
          fraction
          subject {
            ...StatsSubjectFragment
          }
        }
      }

      ... on TrendlineStatsItem {
        color
        values {
          date
          value
        }
      }

      ... on MetricStatsItem {
        values {
          color
          value
          label
        }
      }
    }
  }
`
