import firebase from "@firebase/app"
import "@firebase/auth"

export const app = firebase.initializeApp({
  apiKey: "AIzaSyBlPAQNC1JmqgEfKb6wuj52zovx5s4BxIE",
  authDomain: "dottie-teams.firebaseapp.com",
  databaseURL: "https://dottie-teams.firebaseio.com",
  projectId: "dottie-teams",
  storageBucket: "dottie-teams.appspot.com",
  messagingSenderId: "10208328783",
  appId: "1:10208328783:web:d0e4c618781c3a0264be3a",
  measurementId: "G-HLWGV5512E",
})
