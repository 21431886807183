import React from "react"
import {isAfter, endOfMonth, addMonths, format} from "date-fns"
import {Stack, IconButton, Text} from "@chakra-ui/core"

export default function MonthNavigation({
  from,
  to,
  currentDate,
  setCurrentDate,
}: {
  from: Date | number
  to: Date | number
  currentDate: Date
  setCurrentDate: (date: Date) => void
}) {
  const canGoForward = !isAfter(endOfMonth(currentDate), to)
  const canGoBackward = !isAfter(from, currentDate)

  return (
    <Stack isInline alignItems="center" pl={10} pr={10} pt={2} pb={4}>
      <IconButton
        aria-label="Previous month"
        isDisabled={!canGoBackward}
        onClick={() => setCurrentDate(addMonths(currentDate, -1))}
        variantColor="white"
        icon="arrow-back"
      />

      <Text flex="1" textTransform="uppercase" color="white" textAlign="center">
        {format(currentDate, "MMMM")}
      </Text>
      <IconButton
        aria-label="Next month"
        isDisabled={!canGoForward}
        onClick={() => setCurrentDate(addMonths(currentDate, 1))}
        variantColor="white"
        icon="arrow-forward"
      />
    </Stack>
  )
}
