import React from "react"
import {Box, Text} from "@chakra-ui/core"

export default function SidebarHeader(props: {
  backgroundColor: string
  text: string
}) {
  return (
    <Box
      zIndex={999}
      pt={2}
      mt={4}
      style={{marginBottom: 0}}
      pb={2}
      position="sticky"
      top="0"
      backgroundColor={props.backgroundColor}
    >
      <Text textAlign="center" fontSize="3xl" fontWeight="normal" color="white">
        {props.text}
      </Text>
    </Box>
  )
}
