export const colors = {
  bad: "#e53261",
  ok: "#f79c3a",
  good: "#26e589",
  primary: "rgb(76, 116, 233)",
}

export function serverColor(name: string) {
  return (colors as any)[name] ?? name
}
